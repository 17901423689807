import { library } from '@fortawesome/fontawesome-svg-core';

// Import the icons we use
// We do this to decrease the bundle-size
import {
  faHome,
  faRobot,
  faClipboardList,
  faBrain,
  faLessThanEqual,
  faWrench,
  faInfo,
  faSearch,
  faTimes,
  faCheck,
  faChartLine,
  faAngleDown,
  faAngleUp,
  faTh,
  faTasks,
  faTrashAlt,
  faExclamation,
  faArrowDown,
  faArrowUp,
  faArrowsAlt,
  faPlus,
  faMinus,
  faCog,
  faExchangeAlt,
  faTags,
  faBell,
  faExternalLinkAlt,
  faBars,
  faQuestionCircle,
  faCheckCircle,
  faDownload,
  faRotateRight,
  faLink,
  faEye,
  faEdit,
  faSpinner,
  faStop,
} from '@fortawesome/free-solid-svg-icons';
import {
  faClock,
  faPaperPlane,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faHome,
  faRobot,
  faClipboardList,
  faBrain,
  faLessThanEqual,
  faWrench,
  faInfo,
  faSearch,
  faTimes,
  faClock,
  faCheck,
  faPaperPlane,
  faChartLine,
  faAngleDown,
  faAngleUp,
  faTh,
  faTasks,
  faTrashAlt,
  faExclamation,
  faArrowDown,
  faArrowUp,
  faArrowsAlt,
  faPlus,
  faMinus,
  faCog,
  faExchangeAlt,
  faTags,
  faBell,
  faExternalLinkAlt,
  faBars,
  faQuestionCircle,
  faCheckCircle,
  faDownload,
  faRotateRight,
  faLink,
  faEye,
  faEdit,
  faSpinner,
  faStop,
);

export default library;
